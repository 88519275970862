<template>
  <div id="app-layout">           
    <div class="home__title">
              <h2 class="home__title__bg">LIENS UTILES</h2>
            </div>         
            <div class="pagecontent">
              <div class="container-fluid">
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.journal-officiel.gouv.fr">Journal Officiel - JO</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.infogreffe.fr">Infogreffe - l'information légale sur les entreprises - greffes des Tribunaux de commerce</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://public.notexpert.fr">Expertise immobilière- Groupement des notaires Experts - Notexpert</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.caissedesdepots.fr">Caisse des Dépôts</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.paris.notaires.fr/outil/immobilier/calcul-de-frais-dachat">Calcul des frais de mutation - Acquisition immobilière</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.prim.net">Portail des risques majeurs - Ministère du développement durable</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.successions-europe.eu">Successions en Europe - Le droit des successions dans les 27 pays européens</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.jurisvin.fr">Réseau Jurisvin- Notaires conseils du monde viticole</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.pharmetudes.com">Réseau des notaires au service des pharmaciens - Pharmétudes</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.immobilier.notaires.fr">Immobilier des Notaires de France - Site officiel</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.notaires.fr">Notaires de France - Conseil supérieur du notariat</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.cnue.be">Notaires d'Europe - Conseil des Notariats de l'Union Européenne - CNUE</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.justice.gouv.fr">Ministère de la justice</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.legifrance.gouv.fr">Legifrance - le service public de la diffusion du droit</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.impots.gouv.fr">Impôts - site de l'administration fiscale</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://e-justice.europa.eu/home.do">E-justice - le portail européen de la justice</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.service-public.fr/">Démarches administratives - Service Public</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="https://www.service-public.fr/particuliers/vosdroits/F10871">Prêt à Taux Zéro (PTZ)</a>
                  </div>
                  <div class="liensutiles">
                    <a class="liensutiles__link" href="http://www.cadastre.gouv.fr">Cadastre - Service de consultation du plan cadastral</a>
                  </div>
            </div>
  </div>
  </div>
</template>

<script>
  export default {
    metaInfo: {
    // Children can override the title.
    title: 'Liens Utiles - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Liensutiles'}
   ]
  },
    name: "liens"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}
 .liensutiles {
        background-color: $tertiary-color;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: larger;
        font-weight: 600;
        animation: liensutiles 1000ms;
        animation-fill-mode: backwards;
        @media screen and (max-width: 950px){
            font-size: inherit;
            }
        &__link {
            display: block;
        }
 }

 @keyframes liensutiles {
    0% {
        transform: translateY(10px);
        opacity: 0;
  }
      
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@for $i from 1 through 20 {
    .liensutiles:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.1}s; }
 }

</style>